"use client";
import NavbarPrivate from "@/components/ui/NavbarPrivate";
import NavbarPublic from "@/components/ui/NavbarPublic";
import { useAuthContext } from "@/providers/AuthProvider";
import { useEffect, useState } from "react";

export default function NavbarControl() {
  const { userData } = useAuthContext();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    //console.log("userData", userData);
    if (userData?.id) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [userData]);

  //console.log("isAuthenticated", isAuthenticated);

  return isAuthenticated ? <NavbarPrivate /> : <NavbarPublic />;
}
