export const setPersistRoot = (data: any, property: string) => {
  // Obtener el estado actual de persist:root
  const currentData =
    JSON.parse((localStorage as any).getItem("persist:root")) || {};

  // Actualizar el estado con el nuevo plan
  const updatedData = {
    ...currentData,
    [property]: data,
  };

  // Guardar el estado actualizado en localStorage
  localStorage.setItem("persist:root", JSON.stringify(updatedData));
};

export const removePropertyFromRoot = (property: string) => {
  // Obtener el estado actual de persist:root
  const currentData =
    JSON.parse((localStorage as any).getItem("persist:root")) || {};

  // Eliminar la propiedad especificada
  if (currentData.hasOwnProperty(property)) {
    delete currentData[property];
  }

  // Guardar el estado actualizado en localStorage
  localStorage.setItem("persist:root", JSON.stringify(currentData));
};

export const getPropertyFromRoot = (property: string) => {
  if (typeof window === "undefined" || typeof localStorage === "undefined") {
    return;
  }

  // Obtener el estado actual de persist:root
  const currentData =
    JSON.parse((localStorage as any).getItem("persist:root")) || {};

  // Obtener la propiedad especificada

  if (!currentData[property]) return null;

  return currentData[property];
};

export const removePersistRoot = () => {
  localStorage.removeItem("persist:root");
};
