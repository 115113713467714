import axios from "axios";
import Cookies from "js-cookie";

let currentToken = Cookies.get("mpsession");

const axiosInstance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_API_URL_BASE}`,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

// Interceptor para agregar el token de autorización a todas las solicitudes
axiosInstance.interceptors.request.use(
  async (config) => {
    if (!currentToken) {
      try {
        currentToken = await getTempTkn();
      } catch (error) {
        //console.error("Error obteniendo token temporal:", error);
      }
    }

    if (currentToken) {
      config.headers.Authorization = `Bearer ${currentToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const updateAxiosToken = (newToken) => {
  currentToken = newToken;
};

const getTempTkn = async () => {
  try {
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL_BASE}/auth/temp-tkn`
    );
    const tempTkn = response.data.token;

    return tempTkn;
  } catch (error) {
    //console.error("Error obteniendo token temporal:", error);
    throw error; // Propaga el error para que se maneje externamente
  }
};

export default axiosInstance;
